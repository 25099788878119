import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { withAuthentication } from "../../components/Session";

import * as ROUTES from "../../constants/routes";

import RatingPageInitial from "./ratingInitial";
import RatingPageFirst from "./ratingFirst";
import RatingPageLast from "./ratingLast";
import SuccessPage from "./success";

class RatingPage extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={ROUTES.RATING} component={RatingPageInitial} />
          <Route exact path={ROUTES.RATING_FIRST} component={RatingPageFirst} />
          <Route exact path={ROUTES.RATING_LAST} component={RatingPageLast} />
          <Route exact path={ROUTES.SUCCESS} component={SuccessPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default withAuthentication(RatingPage);
