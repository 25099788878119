import React from "react";

import { General } from "./style.js";

const Footer = props => (
  <General nobackground={props.nobackground}>
    <footer>
      Criado com <i className="fa fa-heart" /> por{" "}
      <a
        href="https://www.evne.net.br"
        target="_blank"
        rel="noopener noreferrer"
      >
        evne
      </a>
      .
    </footer>
  </General>
);

export default Footer;
