import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../../components/Firebase";
import Footer from "../../components/Footer";

import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";

import { Cont } from "./style";

import * as ROUTES from "../../constants/routes";

import { jobs } from '../../constants/jobs'

class RatingPageFirst extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attendence: 0,
      noteAttendence: 0,
      service: 0,
      noteService: 0,
      txtMotiveOthersAttendence: '',
      age: 0,
      gender: 0,
      job: 1, //select já inicia com o job Agrigultor no front
      error: "",
      open: false,
      ...props.location.state
    };
  }

  setAge(event) {
    this.setState({
      age: event.target.value
    });
  }

  setGender(event) {
    this.setState({
      gender: event.target.value
    });
  }

  setJob(event) {
    this.setState({
      job: event.target.value
    });
  }

  /**
   * Foi criado esse método para continuar usando os campos attendence e service com as mesmas 
   * regras de antes
   */
  oldRules(value) {
    if(value == '1' || value == '0') {
      return value;
    }
    else if(value == '5') {
      return '2';
    }
    else if(value == '7') {
      return '3';
    }
    else if(value == '8') {
      return '4';
    }
    else if(value == '10') {
      return '5';
    }
    return null;

  }

  handleRating = async e => {
    e.preventDefault();
    let { attendence, noteAttendence, detailsAttendence, service, noteService, detailsService, 
      txtMotiveOthersAttendence, txtMotiveOthersService, age, gender, job } = this.state;
    
    noteAttendence = attendence;
    noteService = service;
    attendence = this.oldRules(attendence);
    service = this.oldRules(service);

    if (!attendence || attendence === 0) {
      this.setState({
        error: {
          message: "Um erro ocorreu. Por favor, avise um colaborador."
        }
      });
      return;
    } else if (!age || !gender || !job) {
      this.setState({
        error: {
          message: "Por favor, responda todas as perguntas."
        }
      });
      return;
    }    

    if (this.props.firebase) {
      this.props.firebase
        .ratings()
        .add({
          attendence,
          noteAttendence,
          service,
          noteService,
          detailsAttendence,
          txtMotiveOthersAttendence,
          detailsService,
          txtMotiveOthersService,
          age,
          gender,
          job,
          userUid: JSON.parse(localStorage.getItem("authUser")).uid,
          user: JSON.parse(localStorage.getItem("authUser")).name,
          createdAt: new Date()
        })
        .then(() => {
          this.props.history.push(ROUTES.SUCCESS);
        })
        .catch(error => {
          this.setState({
            error: `Um erro ocorreu. Por favor, avise um colaborador. \n ${error}`
          });
        });
    }
  };

  render() {
    return (
      <Cont>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css"
          integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS"
          crossOrigin="anonymous"
        />
        <Container className="fill">
          {this.state.error && (
            <Alert key="danger" variant="danger">
              {this.state.error.message}
            </Alert>
          )}

          <h1>Faixa Etária</h1>

          <div className="radios" onChange={event => this.setAge(event)}>
            <label className="radLast">
              <input type="radio" name="age" value="1" />
              <i className="normal" />
              <span>18-24</span>
            </label>

            <label className="radLast">
              <input type="radio" name="age" value="2" />
              <i className="normal" />
              <span>25-34</span>
            </label>

            <label className="radLast">
              <input type="radio" name="age" value="3" />
              <i className="normal" />
              <span>35-44</span>
            </label>

            <label className="radLast">
              <input type="radio" name="age" value="4" />
              <i className="normal" />
              <span>45-54</span>
            </label>

            <label className="radLast">
              <input type="radio" name="age" value="5" />
              <i className="normal" />
              <span>55-64</span>
            </label>

            <label className="radLast">
              <input type="radio" name="age" value="6" />
              <i className="normal" />
              <span>65 ou mais</span>
            </label>
          </div>

          <div className="linha">
            <div className="coluna">
              <h1>Sexo</h1>

              <div className="radios" onChange={event => this.setGender(event)}>
                <label className="radLast">
                  <input type="radio" name="gender" value="2" />
                  <i className="normal" />
                  <span>Feminino</span>
                </label>

                <label className="radLast">
                  <input type="radio" name="gender" value="1" />
                  <i className="normal" />
                  <span>Masculino</span>
                </label>
              </div>
            </div>

            <div className="coluna">
              <h1>Profissão</h1>

              <label className="radLast">
                <select id="pro" onChange={event => this.setJob(event)} value={this.state.job}>
                  {jobs.map(prof => (
                      <option key={prof.value} value={prof.value} >
                        {prof.name}
                      </option>
                  ))}
                </select>
              </label>
            </div>
          </div>

          <Link to={ROUTES.SUCCESS} onClick={e => this.handleRating(e)}>
            <button className="btn" type="submit">
              FINALIZAR AVALIAÇÃO
            </button>
          </Link>
          <Footer nobackground />
        </Container>
      </Cont>
    );
  }
}

export default withFirebase(RatingPageFirst);
