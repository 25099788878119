import React, { Component } from "react";
import { Link } from "react-router-dom";

import Footer from "../../components/Footer";
import Container from "react-bootstrap/Container";
import { Cont } from "./style";

import Sig from "../../assets/sig_original.png";
import Logo from "../../assets/logo.png";

import * as ROUTES from "../../constants/routes";

export default class RatingPageInitial extends Component {
  render() {
    return (
      <Cont>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css"
          integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS"
          crossOrigin="anonymous"
        />
        <Container className="fill">
          <span className="destaque">
            <strong>RESPONDA NOSSA PESQUISA DE SATISFAÇÃO</strong>
          </span>

          <div className="btnRow">
            <img className="sig" src={Sig} alt="Sig" />
            <Link to={ROUTES.RATING_FIRST}>
              <button type="button" className="btn">
                Iniciar Avaliação
              </button>
            </Link>
            <img className="logo" src={Logo} alt="Logo" />
          </div>

          <Footer nobackground />
        </Container>
      </Cont>
    );
  }
}
