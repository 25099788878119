export const jobs = [
    {
        value: 1,
        name: "Agricultor"
    },
    {
        value: 2,
        name: "Aposentado"
    },
    {
        value: 3,
        name: "Desempregado"
    },
    {
        value: 4,
        name: "Empresário"
    },
    {
        value: 5,
        name: "Estudante"
    },
    {
        value: 6,
        name: "Funcionário"
    },
    {
        value: 7,
        name: "Servidor Público"
    },
    {
        value: 8,
        name: "Motorista"
    },
    {
        value: 9,
        name: "Profissional Liberal"
    }
];
