import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(doc => {
            if (doc.exists) {
              const dbUser = doc.data();

              // Default empty roles
              if (!dbUser.roles) {
                dbUser.roles = [];
              }

              // Merge auth and db user infos
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                ...dbUser
              };
              next(authUser);
            } else {
              fallback();
            }
          });
      } else {
        fallback();
      }
    });

  // *** User API ***
  user = uid => this.db.doc(`users/${uid}`);

  users = () => this.db.collection("users");

  // *** Rating API ***
  rating = uid => this.db.doc(`rating/${uid}`);

  ratings = () => this.db.collection("ratings");

  // *** Counters API ***
  counterAttendence = () => this.db.doc("counters/attendence");

  counterService = () => this.db.doc("counters/service");

  counterAge = () => this.db.doc("counters/age");

  counterGender = () => this.db.doc("counters/gender");

  counterJob = () => this.db.doc("counters/job");
}

export default Firebase;
