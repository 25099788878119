
function motive(motive) {
    switch(motive){
        case '1':
            return 'Realizado de maneira incorreta'
        case '2':
            return 'Técnico não fez bom atendimento'
        case '3':
            return 'Demora para realização'
        case '4':
            return 'Não fui avisado(a) sobre a finalização'
        case '5':
            return 'Preço'
        case '6':
            return 'Outro:'
        default:
            return null
    }
}

module.exports = {motive: motive};