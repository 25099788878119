import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import SignUpPage from "../../pages/SignUp";
import SignInPage from "../../pages/SignIn";
import RatingPage from "../../pages/Rating";
import AccountPage from "../../pages/Account";
import AdminPage from "../../pages/Admin";
import DashobardPage from "../../pages/Dashboard";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";

const App = () => (
  <Router>
    <div>
      <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route exact path={ROUTES.RATING} component={RatingPage} />
      <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route exact path={ROUTES.ADMIN} component={AdminPage} />
      <Route exact path={ROUTES.DASHBOARD} component={DashobardPage} />
    </div>
  </Router>
);

export default withAuthentication(App);
