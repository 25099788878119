import styled, { css } from "styled-components";

export const General = styled.div`
  position: relative;
  bottom: 0;
  clear: both;
  height: 32px;
  padding: 8px;
  text-align: center;
  font-size: 0.8em;
  background: #222d32;
  color: white;

  a {
    text-decoration: none;
    color: white;
    font-weight: 700;
  }

  .fa {
    color: red;
  }

  .nobackground {
    background: white;
    clear: both;

    a {
      color: #222d32;
    }
  }

  ${props =>
    props.nobackground &&
    css`
      background: white;
      color: #222d32;
      a {
        color: #222d32;
      }
    `}
`;
