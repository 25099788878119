import styled from "styled-components";

import Angry from "../../assets/angry.svg";
import Sad from "../../assets/sad.svg";
import Shocked from "../../assets/shocked.svg";
import Happy from "../../assets/happy.svg";
import Love from "../../assets/love.svg";
import Cancel from "../../assets/cancel.svg";

export const Cont = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  background: #fec800;
  padding: 0;
  margin: 0;
  overflow-y: auto;

  .fill {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;
    padding: 1em;
    border: 0;
    border-radius: 5px;
  }

  h1 {
    font-size: 2em;
    text-align: center;
    margin-top: 1em;
  }

  .destaque {
    font-size: 2em;
    margin-top: 2em;
    margin-bottom: 1em;
    text-align: center;
  }

  .btn {
    height: auto;
    padding: 24px;
    background: #fec800;
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 1em;
    border: 0;
    border-radius: 3px;
    margin: 16px 60px;
    margin-bottom: 2em;
    cursor: pointer;

    &:hover {
      background-color: #feb514;
      -webkit-transition: background 0.1s;
      -moz-transition: background 0.1s;
      -o-transition: background 0.1s;
      transition: background 0.1s;
    }
  }

  @media screen and (min-width: 768px) {
    .destaque {
      font-size: 3em;
    }

    .btn {
      font-size: 1.5em;
    }
  }

  select {
    height: auto;
    padding: 16px 8px;
    margin: 16px, 8px 0px, auto;
    background: #fff;
    text-align: center;
    font-size: 1.5em;
    border: 0.5px solid #afafaf;
    border-radius: 3px;
    transition: opacity 0.4s;

    &::placeholder {
      color: #afafaf;
      font-size: 1em;
    }

    &:focus {
      border-color: #fec800;
      box-shadow: none;
    }
  }

  select > option {
    text-align: center;
  }

  .linha {
    display: flex;
    flex-direction: row;
    text-align: center;
  }

  .coluna {
    display: flex;
    flex-direction: column;
  }

  .radios {
    display: flex;
    flex-direction: row;
    color: #444;
    font-size: 1em;
    font-weight: 700;
  }

  .rad {
    margin: 16px;
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-user-select: none;
  }

  .rad > input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .rad > .angry {
    background: url(${Angry});
  }

  .rad > .sad {
    background: url(${Sad});
  }

  .rad > .shocked {
    background: url(${Shocked});
  }

  .rad > .happy {
    background: url(${Happy});
  }

  .rad > .love {
    background: url(${Love});
  }

  .rad > .cancel {
    background: url(${Cancel});
  }

  .rad > i {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
  }

  @media screen and (min-width: 768px) {
    .rad > i {
      width: 80px;
      height: 80px;
    }
  }

  .rad > i,
  .rad > span {
    font-size: 1.5em;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    -webkit-filter: grayscale(1) opacity(0.6);
    -moz-filter: grayscale(1) opacity(0.6);
    filter: grayscale(1) opacity(0.6);
  }

  .rad:hover > i,
  .rad:hover > span {
    -webkit-filter: grayscale(0.15) opacity(0.8);
    -moz-filter: grayscale(0.15) opacity(0.8);
    filter: grayscale(0.15) opacity(0.8);
  }

  .rad > input:checked + i {
    -webkit-filter: none;
    -moz-filter: none;
    filter: none;
  }

  .rad > input:checked + span {
    -webkit-filter: none;
    -moz-filter: none;
    filter: none;
  }

  .radLast {
    margin: 16px;
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }
  .radLast > input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .radLast > i {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
    vertical-align: middle;
    border-radius: 50%;
    transition: 0.2s;
    box-shadow: inset 0 0 0 8px #fff;
    border: 1px solid gray;
  }

  .radLast > span {
    font-size: 1.5em;
  }

  .radLast:hover > i {
    box-shadow: inset 0 0 0 3px #fff;
    background: gray;
  }
  .radLast > input:checked + i {
    box-shadow: inset 0 0 0 3px #fff;
    background: #fec800;
  }

  .btnRow {
    display: flex;

    .sig {
      height: 150px;
      width: 150px;
    }

    .logo {
      height: 100px;
      width: 200px;
    }
  }
`;

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fec800;
  padding: 0;
  margin: 0;
  overflow-y: auto;

  img {
    height: 100%;
  }
`;
