import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../../components/Firebase";

import * as ROUTES from "../../constants/routes";

import { General } from "./style.js";
import Logo from "../../assets/logo.png";

const SignInPage = () => <SignInForm />;

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.RATING);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <General>
        <form onSubmit={this.onSubmit}>
          <img src={Logo} alt="Logo Siga-Bem" />
          <label htmlFor="email">Endereço de E-mail</label>
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="exemplo@exemplo.com"
          />
          <label htmlFor="password">Senha</label>
          <input
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
            placeholder="********"
          />
          <button disabled={isInvalid} type="submit">
            Entrar
          </button>

          {error && <p>{error.message}</p>}
        </form>
      </General>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase
)(SignInFormBase);

export default SignInPage;

export { SignInForm };
