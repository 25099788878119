import styled from "styled-components";

export const General = styled.div`
  table {
    width: calc(100% - 40px);
    margin: 40px auto;
    border-collapse: collapse;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  }

  td {
    border-bottom: 1px solid lightgray;
    padding: 14px;
  }

  tr:hover {
    background-color: lightgray;
  }

  th {
    border-top: 1px solid #32383e;
    border-bottom: 2px solid #32383e;
    padding: 12px;
    text-align: left;
    background-color: #212529;
    color: white;
  }

  .footer {
    border-bottom: 0;
    background-color: white;
    color: black;
  }

  legend {
    font-size: 2em;
    font-weight: 700;
    text-align: center;
    margin-top: 40px;
  }
  .tabela p {
    font-size: 12px;
    margin-top: -30px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .doughnut {
    display: flex;
  }

  .doughnut1,
  .doughnut2 {
    width: calc(50% - 40px);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    margin: 20px;
    padding: 16px;
  }

  .doughnut3,
  .doughnut4,
  .doughnut5 {
    width: calc(33.33% - 40px);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    margin: 20px;
    padding: 16px;
  }

  .filter {
    margin-top: 16px;
    display: flex;
    align-items: center;
    margin-bottom: -25px;

    label {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      font-size: 1em;
    }

    input {
      height: auto;
      padding: 8px;
      margin: 0 auto;
      background: #fcfcfc;
      text-align: left;
      font-size: 1em;
      border: 2px solid #e7e7e7;
      border-radius: 3px;
      transition: opacity 0.4s;

      &::placeholder {
        color: #afafaf;
        font-size: 1em;
      }

      &:focus {
        border-color: #fec800;
        box-shadow: none;
      }
    }

    .btn {
      height: auto;
      padding: 8px 12px;
      margin-left: 16px;
      background: #fec800;
      color: white;
      text-align: center;
      font-size: 1em;
      border-radius: 3px;
      border: 0;
      cursor: pointer;
    }
  }
`;
