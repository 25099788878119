import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";

import { General } from "./style.js";

import Logo from "../../assets/logo.png";

import * as ROUTES from "../../constants/routes";

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />
    }
  </AuthUserContext.Consumer>
);

class NavigationAuthBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: "main-nav"
    };
  }

  handleClick(e) {
    this.setState({
      active: this.state.active === "main-nav" ? "active" : "main-nav"
    });
  }

  render() {
    const { active } = this.state;

    return (
      <General>
        <nav className="navbar">
          <span className="navbar-toggle" id="js-navbar-toggle">
            <i
              className="fa fa-bars"
              onClick={event => this.handleClick(event)}
            />
          </span>
          <Link to={ROUTES.DASHBOARD}>
            <img src={Logo} alt="Logo" />
          </Link>

          <ul className={active} id="js-menu">
            <li>
              <Link className="nav-links" to={ROUTES.RATING}>
                Avaliação
              </Link>
            </li>
            <li>
              <Link className="nav-links" to={ROUTES.DASHBOARD}>
                Painel de Controle
              </Link>
            </li>
            <li>
              <Link className="nav-links" to={ROUTES.SIGN_UP}>
                Cadastro de Usuário
              </Link>
            </li>
            <li>
              <Link
                className="nav-links-sair"
                to="#"
                onClick={this.props.firebase.doSignOut}
              >
                Sair
              </Link>
            </li>
          </ul>
        </nav>
      </General>
    );
  }
}

export const NavigationAuth = withFirebase(NavigationAuthBase);

const NavigationNonAuth = () => (
  <ul>
    <li>
      <Link to={ROUTES.SIGN_IN}>Sign In</Link>
    </li>
  </ul>
);

export default Navigation;
