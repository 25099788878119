import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Container } from "./style.js";
import Sig from "../../assets/sig.png";

import * as ROUTES from "../../constants/routes";

class SuccessPage extends Component {
  state = {
    redirect: false
  };

  componentDidMount() {
    this.id = setTimeout(() => this.props.history.push(ROUTES.RATING), 3000);
  }

  componentWillUnmount() {
    clearTimeout(this.id);
  }

  render() {
    return (
      <Container>
        <img src={Sig} alt="Sig" />
      </Container>
    );
  }
}

export default withRouter(SuccessPage);
