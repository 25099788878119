import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withAuthorization } from "../../components/Session";
import { withFirebase } from "../../components/Firebase";

import { General } from "./style.js";

import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

const SignUpPage = () => <SignUpForm />;

const INITIAL_STATE = {
  name: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  isAdmin: false,
  error: null
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  onSubmit = event => {
    const { name, email, passwordOne, isAdmin } = this.state;

    const roles = [];
    if (isAdmin) {
      roles.push(ROLES.ADMIN);
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        return this.props.firebase.user(authUser.user.uid).set({
          name,
          email,
          roles,
          createdAt: new Date()
        });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.RATING);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      name,
      email,
      passwordOne,
      passwordTwo,
      isAdmin,
      error
    } = this.state;
    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      name === "";

    return (
      <General>
        <form onSubmit={this.onSubmit}>
          <legend>Cadastro</legend>
          <label htmlFor="name">Nome</label>
          <input
            className="text"
            name="name"
            value={name}
            onChange={this.onChange}
            type="text"
            placeholder="João da Silva"
          />

          <label htmlFor="email">Endereço de e-mail</label>
          <input
            className="text"
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="joao@exemplo.com"
          />

          <label htmlFor="passwordOne">Senha</label>
          <input
            className="text"
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="********"
          />

          <label htmlFor="passwordTwo">Confirmação de Senha</label>
          <input
            className="text"
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="********"
          />
          <br />
          <label className="admin">
            <input
              name="isAdmin"
              type="checkbox"
              checked={isAdmin}
              onChange={this.onChangeCheckbox}
            />
            Acesso ao painel administrativo
          </label>
          <button disabled={isInvalid} type="submit">
            Cadastrar
          </button>

          {error && <p>{error.message}</p>}
        </form>
      </General>
    );
  }
}

const SignUpLink = () => (
  <p>
    Não tem uma conta? <Link to={ROUTES.SIGN_UP}>Cadastre-se</Link>
  </p>
);

const SignUpForm = compose(
  withAuthorization(
    authUser => !!authUser && authUser.roles.includes(ROLES.ADMIN)
  ),
  withRouter,
  withFirebase
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
