export const SIGN_UP = "/signup";
export const SIGN_IN = "/";
export const RATING = "/rating";
export const RATING_INITIAL = "/ratinginitial";
export const RATING_FIRST = "/ratingfirst";
export const RATING_LAST = "/ratinglast";
export const ACCOUNT = "/account";
export const SUCCESS = "/success";
export const ADMIN = "/admin";
export const DASHBOARD = "/dashboard";
export const ADMIN_DETAILS = "/admin/:id";
export const PASSWORD_FORGET = "/pw-forget";
