import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Collapse from "react-bootstrap/Collapse";
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/custom.css';
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import _ from "lodash";

import { withAuthorization } from "../../components/Session";
import { withFirebase } from "../../components/Firebase";

import { General } from "./style.js";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";

import { Doughnut } from "react-chartjs-2";

import * as ROLES from "../../constants/roles";
import { jobs } from '../../constants/jobs'
import { typeRatings, typeRatingsOld } from '../../constants/typeRatings';

let ratingListener = null;
let counterAttendenceListener = null;
let counterServiceListener = null;
let counterAgeListener = null;
let counterGenderListener = null;
let counterJobListener = null;
const motive = require('../Rating/enumMotive').motive

const DashboardPage = () => <DashboardForm />;

class DashboardFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      ratings: [],
      users: [],
      counterAttendence: {},
      counterService: {},
      counterAge: {},
      counterGender: {},
      counterJob: {},
      averageAttendence: 0,
      averageService: 0,
      filter: {
        user: undefined,
        initialDate: undefined,
        finalDate: undefined
      },
      showModal: false,
      rating: {}
    };
    this.handleClose = this.handleClose.bind(this);
    this.renderMotives = this.renderMotives.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });

    ratingListener = this.props.firebase
      .ratings()
      .orderBy("createdAt", "desc")
      .limit(100)
      .onSnapshot(querySnapshot => {
        let ratings = [];
        querySnapshot.forEach(doc => {
          ratings.push({ ...doc.data(), uid: doc.id });
        });
        this.setState({
          ratings: ratings,
          loading: false
        });
        this.calcAverage();
      });

    counterAttendenceListener = this.props.firebase
      .counterAttendence()
      .onSnapshot(doc => {
        this.setState({
          counterAttendence: doc.data()
        });
      });

    counterServiceListener = this.props.firebase
      .counterService()
      .onSnapshot(doc => {
        this.setState({
          counterService: doc.data()
        });
      });

    counterAgeListener = this.props.firebase.counterAge().onSnapshot(doc => {
      this.setState({
        counterAge: doc.data()
      });
    });

    counterGenderListener = this.props.firebase
      .counterGender()
      .onSnapshot(doc => {
        this.setState({
          counterGender: doc.data()
        });
      });

    counterJobListener = this.props.firebase.counterJob().onSnapshot(doc => {
      this.setState({
        counterJob: doc.data()
      });
    });

    this.props.firebase
      .users()
      .orderBy("name", "asc")
      .get()
      .then(querySnapshot => {
        let users = [];
        querySnapshot.forEach(doc => {
          users.push({ ...doc.data(), uid: doc.id });
        });
        this.setState({
          users: users
        });
      })
      .catch(err => console.log(err));
    }

  componentWillUnmount() {
    if (ratingListener) ratingListener();
    if (counterAttendenceListener) counterAttendenceListener();
    if (counterServiceListener) counterServiceListener();
    if (counterAgeListener) counterAgeListener();
    if (counterGenderListener) counterGenderListener();
    if (counterJobListener) counterJobListener();
  }

  renderCharts() {
    const {
      counterAttendence,
      counterService,
      counterAge,
      counterGender,
      counterJob
    } = this.state;

    const dataAttendence = {
      datasets: [
        {
          data: [
            counterAttendence[0],
            counterAttendence[1],
            counterAttendence[2],
            counterAttendence[3],
            counterAttendence[4],
            counterAttendence[5]
          ],
          backgroundColor: [
            "#39424e",
            "#ff9aa2",
            "#ffb69b",
            "#fff5ba",
            "#bffcc6",
            "#ace7ff"
          ],
          hoverBackgroundColor: [
            "#39424e",
            "#ff9aa2",
            "#ffb69b",
            "#fff5ba",
            "#bffcc6",
            "#ace7ff"
          ]
        }
      ],

      labels: [
        "Não se Aplica",
        "Péssimo",
        "Ruim",
        "Regular",
        "Bom",
        "Excelente"
      ]
    };

    const dataService = {
      datasets: [
        {
          data: [
            counterService[0],
            counterService[1],
            counterService[2],
            counterService[3],
            counterService[4],
            counterService[5]
          ],
          backgroundColor: [
            "#39424e",
            "#ff9aa2",
            "#ffb69b",
            "#fff5ba",
            "#bffcc6",
            "#ace7ff"
          ],
          hoverBackgroundColor: [
            "#39424e",
            "#ff9aa2",
            "#ffb69b",
            "#fff5ba",
            "#bffcc6",
            "#ace7ff"
          ]
        }
      ],

      labels: [
        "Não se Aplica",
        "Péssimo",
        "Ruim",
        "Regular",
        "Bom",
        "Excelente"
      ]
    };

    const dataAge = {
      datasets: [
        {
          data: [
            counterAge[1],
            counterAge[2],
            counterAge[3],
            counterAge[4],
            counterAge[5],
            counterAge[6]
          ],
          backgroundColor: [
            "#39424e",
            "#ff9aa2",
            "#ffb69b",
            "#fff5ba",
            "#bffcc6",
            "#ace7ff"
          ],
          hoverBackgroundColor: [
            "#39424e",
            "#ff9aa2",
            "#ffb69b",
            "#fff5ba",
            "#bffcc6",
            "#ace7ff"
          ]
        }
      ],

      labels: ["18-24", "25-34", "35-44", "45-54", "55-64", "65 ou mais"]
    };

    const dataGender = {
      datasets: [
        {
          data: [counterGender[1], counterGender[2]],
          backgroundColor: ["#ace7ff", "#ff9aa2"],
          hoverBackgroundColor: ["#ace7ff", "#ff9aa2"]
        }
      ],

      labels: ["Masculino", "Feminino"]
    };

    const dataJob = {
      datasets: [
        {
          data: [
            counterJob[1],
            counterJob[2],
            counterJob[3],
            counterJob[4],
            counterJob[5],
            counterJob[6],
            counterJob[7],
            counterJob[8],
            counterJob[9]
          ],
          backgroundColor: [
            "#39424e",
            "#fc7ba5",
            "#ff9aa2",
            "#ffb69b",
            "#fff5ba",
            "#ffbf66",
            "#bffcc6",
            "#83f7ad",
            "#ace7ff"
          ],
          hoverBackgroundColor: [
            "#39424e",
            "#fc7ba5",
            "#ff9aa2",
            "#ffb69b",
            "#fff5ba",
            "#ffbf66",
            "#bffcc6",
            "#83f7ad",
            "#ace7ff"
          ]
        }
      ],

      labels: [
        "Agricultor",
        "Aposentado",
        "Desempregado",
        "Empresário",
        "Estudante",
        "Funcionário",
        "Servidor Público",
        "Motorista",
        "Profissional Liberal"
      ]
    };

    const chartOptionsAttendence = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "Atendimento Loja",
        fontSize: 18,
        fontColor: "#000"
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var meta = dataset._meta[Object.keys(dataset._meta)[0]];
            var total = meta.total;
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );
            return currentValue + " (" + percentage + "%)";
          },
          title: function(tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          }
        }
      }
    };

    const chartOptionsServices = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "Serviços Realizados",
        fontSize: 18,
        fontColor: "#000"
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var meta = dataset._meta[Object.keys(dataset._meta)[0]];
            var total = meta.total;
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );
            return currentValue + " (" + percentage + "%)";
          },
          title: function(tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          }
        }
      }
    };

    const chartOptionsAge = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "Faixa Etária",
        fontSize: 18,
        fontColor: "#000"
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var meta = dataset._meta[Object.keys(dataset._meta)[0]];
            var total = meta.total;
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );
            return currentValue + " (" + percentage + "%)";
          },
          title: function(tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          }
        }
      }
    };

    const chartOptionsGender = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "Sexo",
        fontSize: 18,
        fontColor: "#000"
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var meta = dataset._meta[Object.keys(dataset._meta)[0]];
            var total = meta.total;
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );
            return currentValue + " (" + percentage + "%)";
          },
          title: function(tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          }
        }
      }
    };

    const chartOptionsJob = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "Profissão",
        fontSize: 18,
        fontColor: "#000"
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var meta = dataset._meta[Object.keys(dataset._meta)[0]];
            var total = meta.total;
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );
            return currentValue + " (" + percentage + "%)";
          },
          title: function(tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          }
        }
      }
    };

    return (
      <div>
        <div className="doughnut">
          <div className="doughnut1">
            <Doughnut
              options={chartOptionsAttendence}
              data={dataAttendence}
              height={250}
            />
          </div>
          <div className="doughnut2">
            <Doughnut
              options={chartOptionsServices}
              data={dataService}
              height={250}
            />
          </div>
        </div>
        <div className="doughnut">
          <div className="doughnut3">
            <Doughnut options={chartOptionsAge} data={dataAge} height={250} />
          </div>
          <div className="doughnut4">
            <Doughnut
              options={chartOptionsGender}
              data={dataGender}
              height={250}
            />
          </div>
          <div className="doughnut5">
            <Doughnut options={chartOptionsJob} data={dataJob} height={250} />
          </div>
        </div>
      </div>
    );
  }

  formatRatings(rating) {
    for(var i=0; i < typeRatings.length; i++) {
      if(typeRatings[i].value == rating) {
          return typeRatings[i].name;
      }
    }
    return "Não se aplica"; 
  }

  formatRatingsOld(rating) {
    for(var i=0; i < typeRatingsOld.length; i++) {
      if(typeRatingsOld[i].value == rating) {
          return typeRatingsOld[i].name;
      }
    }
    return "Não se aplica";
  }

  formatAge(age) {
    switch (age) {
      case "1":
        return "18-24";
      case "2":
        return "25-34";
      case "3":
        return "35-44";
      case "4":
        return "45-54";
      case "5":
        return "55-64";
      case "6":
        return "65 ou mais";

      default:
        return "Não se aplica";
    }
  }

  formatJob(age) {
    for(var i=0; i < jobs.length; i++) {
      if(jobs[i].value == age) {
          return jobs[i].name
      }
    }
    return "Não se aplica"  
  }

  renderRows() {
    const ratings = this.state.ratings || [];
    if (ratings) {
      return ratings.map(rating => (
        <tr key={rating.uid}>
          <td>
            {new Date(rating.createdAt.seconds * 1000).toLocaleString("pt-BR")}
          </td>
          <td>{rating.user}</td>
          <td>
            {
              rating.noteAttendence ? 
              this.formatRatings(rating.noteAttendence) : 
              this.formatRatingsOld(rating.attendence)               
            }
          </td>
          <td>{rating.noteAttendence}</td>
          <td>
            {
              rating.noteAttendence ? 
              this.formatRatings(rating.noteService) :
              this.formatRatingsOld(rating.service)
            }
          </td>
          <td>{rating.noteService}</td>
          <td>{this.formatAge(rating.age)}</td>
          <td>{rating.gender === "1" ? "Masculino" : "Feminino"}</td>
          <td>{this.formatJob(rating.job)}</td>
          <td className='align-center'>
            <Collapse in={(rating.detailsAttendence && rating.detailsAttendence.length !== 0) || 
              rating.detailsService && rating.detailsService.length !== 0} >
              <button className='btn btn-danger' onClick={() => this.openModal(rating)}>Motivos</button>
            </Collapse>
          </td>
        </tr>
      ));
    }
  }

  openModal(rating) {
    this.setState({ 
      showModal: true,
      rating: rating
    })
  }
  
  handleClose() {
    this.setState({ showModal: false, renderMotives: false })
  }

  loadUsers() {
    const users = this.state.users || [];
    if (users) {
      return users.map(user => <option key={user.name} value={user.name} />);
    }
  }

  handleUserList(e) {
    if (e.target.value) {
      const { filter } = this.state;
      this.setState({ filter: { ...filter, user: e.target.value } });
    }
  }

  handleInitialDateFilter(e) {
    const { filter } = this.state;
    const date = new Date(e.target.value);

    this.setState({
      filter: {
        ...filter,
        initialDate: new Date(date.valueOf() + date.getTimezoneOffset() * 60000)
      }
    });
  }

  handleFinalDateFilter(e) {
    const { filter } = this.state;
    const date = new Date(e.target.value);
    date.setDate(date.getDate() + 1);
    date.setSeconds(date.getSeconds() - 1);

    this.setState({
      filter: {
        ...filter,
        finalDate: new Date(date.valueOf() + date.getTimezoneOffset() * 60000)
      }
    });
  }

  handleFilter(e) {
    const { user, initialDate, finalDate } = this.state.filter;

    let rating = this.props.firebase.ratings();

    if (user) {
      rating = rating.where("user", "==", user);
    }

    if (initialDate) {
      rating = rating.where("createdAt", ">=", initialDate);
    }

    if (finalDate) {
      rating = rating.where("createdAt", "<=", finalDate);
    }

    rating = rating
      .orderBy("createdAt", "desc")
      .limit(100)
      .onSnapshot(querySnapshot => {
        let ratings = [];
        querySnapshot.forEach(doc => {
          ratings.push({ ...doc.data(), uid: doc.id });
        });
        this.setState({
          ratings: ratings
        });
        this.calcAverage()
      });

    ratingListener = rating;

  }

  calcAverage() {
    let countAttendence = 0
    let sumAttendence = 0
    let countService = 0
    let sumService = 0
    this.state.ratings.forEach(obj => {
      if(obj.noteAttendence && obj.noteAttendence != '0'){
        sumAttendence += parseInt(obj.noteAttendence)
        countAttendence++
      }
      if(obj.noteService && obj.noteService != '0'){
        sumService += parseInt(obj.noteService)
        countService++
      }
    });
    this.setState({
      averageAttendence: sumAttendence / countAttendence,
      averageService: sumService /countService
    })
  }

  localeAverage(num) {
    num = num.toFixed(2);
    num = num + '';
    return num.replace('.', ',');
  }

  render() {
    const { loading } = this.state;
    return (
      <General>
        <Navigation />
        <legend>Avaliações</legend>
        {loading && <div>Loading ...</div>}
        {this.renderCharts()}
        <hr />
        <div className="filter">
          <label>
            Usuário
            <input
              list="users"
              placeholder="Usuário"
              onChange={e => this.handleUserList(e)}
            />
            <datalist id="users">{this.loadUsers()}</datalist>
          </label>
          <label>
            Data Inicial
            <input
              type="date"
              onChange={e => this.handleInitialDateFilter(e)}
            />
          </label>
          <label>
            Data Final
            <input type="date" onChange={e => this.handleFinalDateFilter(e)} />
          </label>
          <label>
             
            <button
              className="btn"
              type="submit"
              onClick={e => this.handleFilter(e)}
            >
              Filtrar
            </button>
          </label>
        </div>
        <br/>
        <br/>
          <div className="row" style={{textAlign: 'center'}}>
            <div className='row-sm-4 average-div'>
              <span className='span-bold'>Média Atendimento Loja: </span>
              <br/>
              <span className='span-bold-size'>{this.localeAverage(this.state.averageAttendence)} </span>
            </div>
            <div className='row-sm-4 average-div'>
              <span className='span-bold'>Média Serviços Realizados: </span>
              <br/>
              <span className='span-bold-size'>{this.localeAverage(this.state.averageService)} </span>
            </div>
          </div>
        
        <div className="tabela">
          <Table rows={this.renderRows()} />
          <p>
            Essa tabela é limitada a 50 registros por questões de performance.
          </p>
        </div>
        <Footer />
        <Modal show={this.state.showModal} onHide={this.handleClose} dialogClassName="my-modal">
          <Modal.Header><h4 className='align-center'>Detalhes dos Motivos da Insatisfação</h4></Modal.Header>
          <Modal.Body>
            <Collapse in={this.state.rating.detailsAttendence && this.state.rating.detailsAttendence.length !== 0}>
              <div>
                <h6>Motivos da Insatisfação do Atendimento da Loja</h6>
                {this.renderMotives('detailsAttendence', this.state.rating)}
              </div>
            </Collapse>
            <Collapse in={this.state.rating.detailsService && this.state.rating.detailsService.length !== 0}>
              <div>
                <h6>Motivos da Insatisfação dos Serviços Realizados:</h6>
                {this.renderMotives('detailsService', this.state.rating)}                
              </div>
            </Collapse>
          </Modal.Body>
          <Modal.Footer>
          <button className='btn btn-danger' onClick={this.handleClose}>Sair</button>
          </Modal.Footer>
        </Modal>
      </General>

    );
  }

  renderMotives(type, rating) {    
    //fiz esse tratamento porque inseri um registro com o details como string
    let details = []
    if(type === 'detailsService' && rating.detailsService && rating.detailsService.length !== 0){
      details = rating.detailsService
    }
    else {
      details = rating.detailsAttendence
    }
    
    if(details && details.length !== 0){
      return details.map(obj => (
          <ul className="list-group" key={obj}>
            <li className="list-group-item">
              {motive(obj)}
              <Collapse in={obj == '6' && rating.detailsAttendence && rating.detailsAttendence.length !== 0}>
                <div>
                  {rating.txtMotiveOthersAttendence}
                </div>  
              </Collapse>
              <Collapse in={obj == '6' && rating.detailsService && rating.detailsService.length !== 0}>
                <div>
                  {rating.txtMotiveOthersService}
                </div>  
              </Collapse>
            </li>
          </ul>
        ))
      }
      return (
        <div></div>
        )
    
  }
  
}


const Table = props => (
  <table>
    <thead>
      <tr>
        <th>Data</th>
        <th>Usuário</th>
        <th>Atendimento Loja</th>
        <th>Nota</th>
        <th>Serviços Realizados</th>
        <th>Nota</th>
        <th>Faixa Etária</th>
        <th>Sexo</th>
        <th>Profissão</th>
        <th>Motivos da Insatisfação</th>
      </tr>
    </thead>
    <tbody>{props.rows}</tbody>
    <tfoot>
      <tr>
        <th className="footer">Total: {_.size(props.rows)}</th>
        <th className="footer" />
        <th className="footer" />
        <th className="footer" />
        <th className="footer" />
        <th className="footer" />
        <th className="footer" />
      </tr>
    </tfoot>
  </table>
);

const DashboardForm = compose(
  withAuthorization(
    authUser => !!authUser && authUser.roles.includes(ROLES.ADMIN)
  ),
  withRouter,
  withFirebase
)(DashboardFormBase);

export default DashboardPage;

export { DashboardForm };
