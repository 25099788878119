import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../../components/Firebase";
import { withAuthorization } from "../../components/Session";

import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";
import Navigation from "../../components/Navigation";

let usersListener = null;
let userListener = null;

class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: []
    };
  }

  render() {
    const { loading } = this.state;

    return (
      <div>
        <h1>Admin</h1>
        <br />
        {loading && <div>Loading ...</div>}

        <Switch>
          <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
          <Route exact path={ROUTES.ADMIN} component={UserList} />
        </Switch>
      </div>
    );
  }
}

class UserListBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      users: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    usersListener = this.props.firebase.users().onSnapshot(querySnapshot => {
      let users = [];
      querySnapshot.forEach(doc => {
        users.push({ ...doc.data(), uid: doc.id });
      });
      this.setState({
        users: users,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    if (usersListener) {
      usersListener();
    }
  }
  render() {
    const { users, loading } = this.state;
    return (
      <div>
        <Navigation />
        <h2>Users</h2>
        {loading && <div>Loading ...</div>}
        <ul>
          {users.map(user => (
            <li key={user.uid}>
              <span>
                <strong>ID:</strong> {user.uid}
              </span>
              <span>
                <strong>E-Mail:</strong> {user.email}
              </span>
              <span>
                <strong>Nome:</strong> {user.name}
              </span>
              <span>
                <Link
                  to={{
                    pathname: `${ROUTES.ADMIN}/${user.uid}`,
                    state: { user }
                  }}
                >
                  Details
                </Link>
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

class UserItemBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      user: null,
      ...props.location.state
    };
  }
  componentDidMount() {
    if (this.state.user) {
      return;
    }

    this.setState({ loading: true });

    userListener = this.props.firebase
      .user(this.props.match.params.id)
      .onSnapshot(querySnapshot => {
        this.setState({
          user: querySnapshot.data(),
          loading: false
        });
      });
  }
  componentWillUnmount() {
    if (userListener) {
      userListener();
    }
  }

  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.state.user.email);
  };

  render() {
    const { user, loading } = this.state;
    return (
      <div>
        {loading && <div>Loading ...</div>}
        {user && (
          <div>
            <h2>User ({user.email})</h2>
            <span>
              <strong>ID:</strong> {user.uid}
            </span>
            <span>
              <strong>E-Mail:</strong> {user.email}
            </span>
            <span>
              <strong>Name:</strong> {user.name}
            </span>
            <span>
              <button type="button" onClick={this.onSendPasswordResetEmail}>
                Send Password Reset
              </button>
            </span>
          </div>
        )}
      </div>
    );
  }
}

const condition = authUser => authUser && authUser.roles.includes(ROLES.ADMIN);

const UserList = withFirebase(UserListBase);
const UserItem = withFirebase(UserItemBase);

export default compose(withAuthorization(condition))(AdminPage);
