import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../../components/Firebase";
import Footer from "../../components/Footer";

import Container from "react-bootstrap/Container";
import Collapse from "react-bootstrap/Collapse";
import Alert from "react-bootstrap/Alert";

import { Cont } from "./style";
import '../../assets/css/custom.css'

import * as ROUTES from "../../constants/routes";

class RatingPageFirst extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attendence: 0,
      service: 0,
      error: "",
      detailsAttendence: [],
      detailsService: [],
      txtMotiveOthersAttendence: '',
      txtMotiveOthersService: '',
      open: false,
      openDetailAttendence: false,
      openDetailService: false,
      openMotiveOthersAttendence: false,
      openMotiveOthersService: false
    };
  }

  setAttendenceRating(event) {
    this.setState({
      attendence: event.target.value
    });
    if (event.target.value === "1" || event.target.value === "5") {
      this.setState({ openDetailAttendence: true })
    }
    else {
      this.setState({ 
        openDetailAttendence: false,
        detailsAttendence: []
      })
    }
  }

  setServiceRating(event) {
    this.setState({
      service: event.target.value
    });
    if (event.target.value === "1" || event.target.value === "5") {
      this.setState({ openDetailService: true })
    }
    else {
      this.setState({ 
        openDetailService: false,
        detailsService: []
      })
    }
  }

  handleClick = async e => {
    e.preventDefault();
    let { attendence, service, detailsAttendence, txtMotiveOthersAttendence, detailsService, txtMotiveOthersService } = this.state;

    if (!attendence || attendence === 0) {
      this.setState({
        error: { message: "Selecione um emoji antes de continuar." }
      });
      return;
    }

    this.props.history.push({
      pathname: `${ROUTES.RATING_LAST}`,
      state: { attendence, service, detailsAttendence, txtMotiveOthersAttendence, detailsService, txtMotiveOthersService }
    });
  };

  setDetailAttendence(event) {
    this.addEstadoDetailAttendence(event.target.value)

    if(this.state.detailsAttendence.indexOf('6', 0) !== -1){
      this.setState({
        openMotiveOthersAttendence: true
      })
    }
    else {
      this.setState({
        openMotiveOthersAttendence: false,
        txtMotiveOthersAttendence: ''
      })
    }
  }

  addEstadoDetailAttendence(value) {
    let lista = this.state.detailsAttendence
    if (lista != [] && lista.indexOf(value, 0) !== -1) {
      lista.splice(lista.indexOf(value, 0), 1)
    }
    else {
      lista.push(value)
    }
    this.setState({ detailsAttendence: lista })
  }

  setMotiveOthersAttendence(event) {
    this.setState({ 
      txtMotiveOthersAttendence: event.target.value
    })
  }

  setDetailService(event) {
    this.addEstadoDetailService(event.target.value)

    if(this.state.detailsService.indexOf('6', 0) !== -1){
      this.setState({
        openMotiveOthersService: true
      })
    }
    else {
      this.setState({
        openMotiveOthersService: false,
        txtMotiveOthersService: ''
      })
    }
  }
  
  addEstadoDetailService(value) {
    let lista = this.state.detailsService
    if (lista != [] && lista.indexOf(value, 0) !== -1) {
      lista.splice(lista.indexOf(value, 0), 1)
    }
    else {
      lista.push(value)
    }
    this.setState({ detailsService: lista })
  }

  setMotiveOthersService(event) {
    this.setState({ 
      txtMotiveOthersService: event.target.value
    })
  }

  render() {
    return (
      <Cont>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css"
          integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS"
          crossOrigin="anonymous"
        />
        <Container className="fill">
          {this.state.error && (
            <Alert key="danger" variant="danger">
              {this.state.error.message}
            </Alert>
          )}

          <h1>Atendimento Loja</h1>

          <div
            className="radios"
            onClick={() => this.setState({ open: true })}
          >
            <label className="rad">
              <input type="radio" name="attendence" value="1" 
                onChange={event => this.setAttendenceRating(event)} />
              <i className="angry" />
              <span>Péssimo</span>
            </label>

            <label className="rad">
              <input type="radio" name="attendence" value="5" 
                onChange={event => this.setAttendenceRating(event)} />
              <i className="sad" />
              <span>Ruim</span>
            </label>

            <label className="rad">
              <input type="radio" name="attendence" value="7" 
                onChange={event => this.setAttendenceRating(event)} />
              <i className="shocked" />
              <span>Regular</span>
            </label>

            <label className="rad">
              <input type="radio" name="attendence" value="8" 
                onChange={event => this.setAttendenceRating(event)} />
              <i className="happy" />
              <span>Bom</span>
            </label>

            <label className="rad">
              <input type="radio" name="attendence" value="10" 
                onChange={event => this.setAttendenceRating(event)} />
              <i className="love" />
              <span>Excelente</span>
            </label>

          </div>

          <Collapse in={this.state.openDetailAttendence}>
            <div>
              <div
                onChange={event => this.setDetailAttendence(event)}
              >
                <h6 className='motivo-h'>Qual o motivo(s) da insatisfação:</h6>

                <label className="checkbox">
                  <input type="checkbox" className="check-item" name="detailAttendence" value="1" />
                  <span>Realizado de maneira incorreta</span>
                </label>

                <label className="checkbox">
                  <input type="checkbox" className="check-item" name="detailAttendence" value="2" />
                  <span>Técnico não fez bom atendimento</span>
                </label>

                <label className="checkbox">
                  <input type="checkbox" className="check-item" name="detailAttendence" value="3" />
                  <span>Demora para realização</span>
                </label>

                <label className="checkbox">
                  <input type="checkbox" className="check-item" name="detailAttendence" value="4" />
                  <span>Não fui avisado(a) sobre a finalização</span>
                </label>

                <label className="checkbox">
                  <input type="checkbox" className="check-item" name="detailAttendence" value="5" />
                  <span>Preço</span>
                </label>

                <label className="checkbox">
                  <input type="checkbox" className="check-item" name="detailAttendence" value="6" />
                  <span>Outro</span>
                </label>

              </div>

            <Collapse in={this.state.openMotiveOthersAttendence}>
              <div className="form-group motivo-h" >
                <label htmlFor="exampleFormControlTextarea1">Motivo da insatisfação:</label>
                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" 
                  onChange={event => this.setMotiveOthersAttendence(event)} ></textarea>
              </div>
            </Collapse>


            </div>
          </Collapse>

          <Collapse in={this.state.open}>
            <div>
              <h1>Serviços Realizados</h1>

              <div
                className="radios"
                onClick={() => this.setState({ open: true })}
              >
                <label className="rad">
                  <input type="radio" name="service" value="1" 
                    onChange={event => this.setServiceRating(event)} />
                  <i className="angry" />
                  <span>Péssimo</span>
                </label>

                <label className="rad">
                  <input type="radio" name="service" value="5" 
                  onChange={event => this.setServiceRating(event)} />
                  <i className="sad" />
                  <span>Ruim</span>
                </label>

                <label className="rad">
                  <input type="radio" name="service" value="7" 
                    onChange={event => this.setServiceRating(event)} />
                  <i className="shocked" />
                  <span>Regular</span>
                </label>

                <label className="rad">
                  <input type="radio" name="service" value="8" 
                    onChange={event => this.setServiceRating(event)} />
                  <i className="happy" />
                  <span>Bom</span>
                </label>

                <label className="rad">
                  <input type="radio" name="service" value="10" 
                    onChange={event => this.setServiceRating(event)} />
                  <i className="love" />
                  <span>Excelente</span>
                </label>
                <label className="rad">
                  <input type="radio" name="service" value="0" 
                    onChange={event => this.setServiceRating(event)} />
                  <i className="cancel" />
                  <span>Não se aplica</span>
                </label>
              </div>

            </div>
          </Collapse>

          <Collapse in={this.state.openDetailService}>
            <div>
              <div
                onChange={event => this.setDetailService(event)}
                >
                <h6 className='motivo-h'>Qual o motivo(s) da insatisfação:</h6>

                <label className="checkbox">
                  <input type="checkbox" className="check-item" name="detailService" value="1" />
                  <span>Realizado de maneira incorreta</span>
                </label>

                <label className="checkbox">
                  <input type="checkbox" className="check-item" name="detailService" value="2" />
                  <span>Técnico não fez bom atendimento</span>
                </label>

                <label className="checkbox">
                  <input type="checkbox" className="check-item" name="detailService" value="3" />
                  <span>Demora para realização</span>
                </label>

                <label className="checkbox">
                  <input type="checkbox" className="check-item" name="detailService" value="4" />
                  <span>Não fui avisado(a) sobre a finalização</span>
                </label>

                <label className="checkbox">
                  <input type="checkbox" className="check-item" name="detailService" value="5" />
                  <span>Preço</span>
                </label>

                <label className="checkbox">
                  <input type="checkbox" className="check-item" name="detailService" value="6" />
                  <span>Outro</span>
                </label>
              </div>

              <Collapse in={this.state.openMotiveOthersService}>
                <div className="form-group motivo-h" >
                  <label htmlFor="exampleFormControlTextarea2">Motivo da insatisfação:</label>
                  <textarea className="form-control" id="exampleFormControlTextarea2" rows="3" 
                    onChange={event => this.setMotiveOthersService(event)} ></textarea>
                </div>
              </Collapse>

            </div>
          </Collapse>

          <Link to={ROUTES.RATING_LAST} onClick={e => this.handleClick(e)}>
            <button className="btn" type="submit">
              AVANÇAR
            </button>
          </Link>
          <Footer nobackground />
        </Container>
      </Cont>
    );
  }
}

export default withFirebase(RatingPageFirst);
