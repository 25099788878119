import styled from "styled-components";

export const General = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  background: #fec800;
  padding: 0;
  margin: 0;

  form {
    width: 470px;
    background: white;
    margin: 0 auto;
    border-radius: 3px;
    padding: 40px;
  }

  legend {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 24px;
  }

  label {
    width: 100%;
    height: auto;
    display: block;
    color: #444444;
    text-align: left;
    font-size: 16px;
    -webkit-appearance: none;
    margin-bottom: -8px;
  }

  .text,
  button,
  p {
    width: 100%;
    height: auto;
    display: block;
    padding: 16px;
    margin: 16px auto;
    background: #fcfcfc;
    text-align: left;
    font-size: 16px;
    border: 2px solid #e7e7e7;
    border-radius: 3px;
    transition: opacity 0.4s;
    -webkit-appearance: none;

    &::placeholder {
      color: #afafaf;
      font-size: 16px;
    }

    &:focus {
      border-color: #fec800;
      box-shadow: none;
    }
  }

  .admin {
    display: inline-flex;
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 16px;
    align-items: center;
    justify-items: center;
  }

  button {
    background: #fec800;
    color: white;
    text-align: center;
    font-weight: 700;
    border: 0;
    margin-bottom: 0;
    cursor: pointer;

    &:hover {
      background-color: #feb514;
      -webkit-transition: background 0.1s;
      -moz-transition: background 0.1s;
      -o-transition: background 0.1s;
      transition: background 0.1s;
    }

    &:disabled {
      -webkit-filter: grayscale(1) opacity(0.6);
      -moz-filter: grayscale(1) opacity(0.6);
      filter: grayscale(1) opacity(0.6);
      cursor: default;
    }
  }

  p {
    background: #ff3333;
    color: white;
    text-align: center;
    border: 2px solid #ff3333;
    border-radius: 3px;
  }
`;
