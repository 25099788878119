import styled from "styled-components";

export const General = styled.div`
  .navbar {
    font-size: 18px;
    background: #222d32;
    border-bottom: 1px solid #777777;
    padding-bottom: 12px;
  }

  .main-nav {
    list-style-type: none;
    display: none;
  }

  .active {
    list-style-type: none;
    display: block;
  }

  .nav-links,
  .nav-links-sair {
    text-decoration: none;
    color: white;
    opacity: 0.8;
    -webkit-filter: opacity(0.8);
    -moz-filter: opacity(0.8);
    filter: opacity(0.8);
  }

  .nav-links-sair {
    color: red;
  }

  .main-nav li,
  .active li {
    text-align: center;
    margin: 15px auto;
  }

  img {
    height: 50px;
    display: inline-block;
    font-size: 22px;
    margin-top: 10px;
    margin-left: 20px;
  }

  .navbar-toggle {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }

  @media screen and (min-width: 768px) {
    .navbar {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0;
      height: 70px;
      align-items: center;
    }

    .main-nav,
    .active {
      display: flex;
      margin-right: 30px;
      flex-direction: row;
      justify-content: flex-end;
    }

    .main-nav li {
      margin: 0;
    }

    .nav-links,
    .nav-links-sair {
      margin-left: 40px;
    }

    .logo {
      margin-top: 0;
    }

    .navbar-toggle {
      display: none;
    }

    .nav-links:hover,
    .nav-links-sair:hover {
      opacity: 1;
      -webkit-filter: opacity(1);
      -moz-filter: opacity(1);
      filter: opacity(1);
    }
  }
`;
