export const typeRatings = [
    {
        value: 1,
        name: "Péssimo"
    },
    {
        value: 5,
        name: "Ruim"
    },
    {
        value: 7,
        name: "Regular"
    },
    {
        value: 8,
        name: "Bom"
    },
    {
        value: 10,
        name: "Excelente"
    }
];

export const typeRatingsOld = [
    {
        value: 1,
        name: "Péssimo"
    },
    {
        value: 2,
        name: "Ruim"
    },
    {
        value: 3,
        name: "Regular"
    },
    {
        value: 4,
        name: "Bom"
    },
    {
        value: 5,
        name: "Excelente"
    }
]